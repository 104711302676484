import { FC, Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import NoConnection from "src/pages/NoConnection"

export const BaseLayout: FC = () => {
  const [isOffline, setIsOffline] = useState(false)

  const handleOfflineEvent = () => {
    setTimeout(() => {
      !navigator.onLine && setIsOffline(true)
    }, 3000)
  }

  const handleRecheckConnection = () => {
    if (navigator.onLine) {
      setIsOffline(false)
    }
  }

  useEffect(() => {
    window.addEventListener("offline", handleOfflineEvent)

    return () => {
      window.removeEventListener("offline", handleOfflineEvent)
    }
  }, [])

  return (
    <Suspense>{!isOffline ? <Outlet /> : <NoConnection handleRecheckConnection={handleRecheckConnection} />}</Suspense>
  )
}
