import { Layout } from "antd"
import React from "react"

import { TourProvider } from "src/components/Tour"

import { useBreakpoints } from "src/hooks/useBreakpoints"

import { BaseLayout } from "../BaseLayout"
import { AccountHeader } from "./AccountHeader"
import { AccountSidebar } from "./AccountSidebar"

export const AccountLayout: React.FC = () => {
  const { isMobile } = useBreakpoints()

  return (
    <TourProvider>
      <Layout className={"account-layout"}>
        {isMobile ? <AccountHeader /> : <AccountSidebar />}

        <Layout.Content>
          <BaseLayout />
        </Layout.Content>
      </Layout>
    </TourProvider>
  )
}
