import { Spin } from "antd"
import { FC, lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import { AccountLayout, BaseLayout } from "src/components/Layouts"
import Page404 from "./Page404"

const Homepage = lazy<FC>(() => import("./Homepage"))
const SignUpLogin = lazy<FC>(() => import("./SignUpLogin"))
const Profile = lazy<FC>(() => import("./Profile"))
const Leaderboard = lazy<FC>(() => import("./Leaderboard"))
const videoLibrary = lazy<FC>(() => import("./videoLibrary"))
const HowItWorks = lazy<FC>(() => import("./howItWorks"))
const BrandUpdates = lazy<FC>(() => import("./brandUpdates"))
const SinglePost = lazy<FC>(() => import("./brandUpdates/page"))
const Report = lazy<FC>(() => import("./Report"))
const TermsAndConditions = lazy<FC>(() => import("./termsAndConditions"))
const ActivityLog = lazy<FC>(() => import("./activityLog"))
const DSCRanking = lazy<FC>(() => import("./dscRanking"))
const ComingSoon = lazy<FC>(() => import("./ComingSoon"))
const TestPage = lazy<FC>(() => import("./TestPage"))

export const comingSoonRoutes: RouteObject[] = [
  {
    Component: ComingSoon,
    loader: () => <Spin spinning />,
    path: "/",
  },
  { Component: () => <Navigate to={"/"} />, loader: () => <Spin spinning />, path: "*" },
  {
    Component: TermsAndConditions,
    path: "/terms",
  },
]

export const guestRoutes: RouteObject[] = [
  {
    Component: BaseLayout,
    loader: () => <Spin spinning />,
    children: [
      {
        Component: () => <Navigate to={"/login"} />,
        path: "/",
        index: true,
      },
      {
        Component: SignUpLogin,
        path: "/login",
      },
      {
        Component: TestPage,
        path: "/test",
      },
      {
        Component: TermsAndConditions,
        path: "/terms",
      },
      {
        Component: videoLibrary,
        path: "account/library",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/*",
      },
      {
        Component: BrandUpdates,
        path: "account/news",
      },
      {
        Component: SinglePost,
        path: "account/news/*",
      },
      {
        Component: Page404,
        path: "*",
      },
    ],
  },
]

export const activatedSalonRoutes: RouteObject[] = [
  {
    Component: AccountLayout,
    loader: () => <Spin spinning />,
    children: [
      {
        Component: Homepage,
        path: "account/homepage",
        index: true,
      },
      {
        Component: Profile,
        path: "account/profile",
      },
      {
        Component: Profile,
        path: "account/profile/account",
      },
      {
        Component: Profile,
        path: "account/profile/notification",
      },
      {
        Component: Profile,
        path: "account/profile/team",
      },
      {
        Component: Profile,
        path: "account/profile/info",
      },
      {
        Component: Profile,
        path: "account/profile/legal",
      },
      {
        Component: Leaderboard,
        path: "account/leaderboard",
      },
      {
        Component: videoLibrary,
        path: "account/library",
      },
      {
        Component: () => <Navigate to={"/account/how-it-works/about"} />,
        loader: () => <Spin spinning />,
        path: "account/how-it-works",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/about",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/categories",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/prizes",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/timeline",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/submission-and-validation",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/archive",
      },
      {
        Component: BrandUpdates,
        path: "account/news",
      },
      {
        Component: SinglePost,
        path: "account/news/*",
      },
      {
        Component: TermsAndConditions,
        path: "/terms",
      },
      {
        Component: Page404,
        path: "*",
      },
    ],
  },
]

export const otherLevelRoutes: RouteObject[] = [
  {
    Component: AccountLayout,
    loader: () => <Spin spinning />,
    children: [
      {
        Component: Homepage,
        path: "account/homepage",
        index: true,
      },
      {
        Component: Leaderboard,
        path: "account/leaderboard",
      },
      {
        Component: videoLibrary,
        path: "account/library",
      },
      {
        Component: () => <Navigate to={"/account/how-it-works/about"} />,
        loader: () => <Spin spinning />,
        path: "account/how-it-works",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/about",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/categories",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/prizes",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/timeline",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/submission-and-validation",
      },
      {
        Component: HowItWorks,
        path: "account/how-it-works/archive",
      },
      {
        Component: BrandUpdates,
        path: "account/news",
      },
      {
        Component: SinglePost,
        path: "account/news/*",
      },
      {
        Component: Report,
        path: "account/report",
      },
      {
        Component: TermsAndConditions,
        path: "account/terms",
      },
      {
        Component: ActivityLog,
        path: "account/activity",
      },
      {
        Component: DSCRanking,
        path: "account/dsc-ranking",
      },
      {
        Component: Page404,
        path: "*",
      },
    ],
  },
]
