import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, QueryResult } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { App as AntApp, ConfigProvider } from "antd"
import _ from "lodash"
import React, { createContext, memo, useContext, useEffect, useState, type FC, type PropsWithChildren } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import introspection, { useHomeQuery } from "src/graphql"
import { activatedSalonRoutes, comingSoonRoutes, guestRoutes, otherLevelRoutes } from "src/pages"
import theme from "src/themes"
import { AuthProvider, useAuth } from "./Auth"

import "./index.less"

const url = import.meta.env.WEBSITE_API_URL

const getNewToken = async () => {
  return await fetch((url?.replace("/graphql", "") || "") + "/api/token/refresh", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refreshToken: localStorage.getItem("refreshToken"),
    }),
  })
    .then(response => response.json())
    .then(response => {
      if (response?.accessToken && response?.refreshToken) {
        localStorage.setItem("accessToken", response.accessToken as string)
        return response.accessToken
      } else {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
      }
    })
}

const httpLink = createHttpLink({
  uri: `${url ?? "/graphql"}`,
  credentials: "same-origin",
})

const authLink = setContext(async (_, { headers }) => {
  let accessToken = localStorage.getItem("accessToken")

  if (!accessToken) {
    accessToken = await getNewToken()
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (
    (graphQLErrors?.length && graphQLErrors?.find(({ extensions }) => extensions?.code == "FORBIDDEN")) ||
    networkError?.message?.includes("401")
  ) {
    localStorage.removeItem("accessToken")
  }
  return forward(operation)
})

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

type ContextProps = {
  app: {
    isPageReady: boolean
    userId: number | undefined
    setSalonData: React.Dispatch<React.SetStateAction<SalonDataInterface | undefined>>
    salonData: SalonDataInterface | undefined
    setIsPageReady: React.Dispatch<React.SetStateAction<boolean>>
    userType: string | undefined
    userTypeId: string | undefined | null
    userName: string | undefined | null
    isNotificationOn: boolean
    setIsNotificationOn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export interface SalonDataInterface {
  salon: GetSalonQuery["salon"]
  stylists: GetStylistsQuery["stylists"]
  submissions: GetSalonSubmissionsQuery["submissions"]
}

export interface PreRegisterDataInterface {
  email: string
  salon: QueryResult<GetSalonsQuery, GetSalonsQueryVariables>
  stylists: QueryResult<GetStylistsQuery, GetStylistsQueryVariables>
}

const Context = createContext<ContextProps>({
  app: {
    isPageReady: false,
    userId: undefined,
    setSalonData: () => null,
    salonData: undefined,
    setIsPageReady: () => null,
    userType: undefined,
    userTypeId: undefined,
    userName: undefined,
    isNotificationOn: false,
    setIsNotificationOn: () => null,
  },
})

const useApp: () => ContextProps = () => useContext(Context)

const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth()
  //here we get data of authorized user
  const [isPageReady, setIsPageReady] = useState<boolean>(false)
  //here we should detect when data from backend is ready
  const [userId, setUserId] = useState<number | undefined>()
  //here we authorize user by id and save the id
  const [userType, setUserType] = useState<string>("")
  //here we detect user id from collection (id of salon, id of DSC etc.)
  const [userTypeId, setUserTypeId] = useState<string>("")
  //here we detect user type ("salon", "DSC" etc.)
  const [userName, setUserName] = useState<string>()
  //here we detect username if it is not salon
  const [isNotificationOn, setIsNotificationOn] = useState<boolean>(true)
  //here we store state of notifications inside homepage
  const [salonData, setSalonData] = useState<SalonDataInterface>()
  //here we store salon data for ability to use it in homepage and in profile page

  const app: ContextProps["app"] = {
    isPageReady: isPageReady,
    userId: userId,
    setSalonData,
    salonData,
    setIsPageReady,
    userType,
    userTypeId,
    userName,
    isNotificationOn,
    setIsNotificationOn,
  }

  useEffect(() => {
    if (user) {
      setUserId(Number(user.id!))
      setUserType(_.get(user, "role.data.attributes.name", "").toLowerCase())
      setUserTypeId(
        _.get(user, "salon.data.id") ||
          _.get(user, "distributor.data.id") ||
          _.get(user, "sales_manager.data.id") ||
          _.get(user, "dsc.data.id") ||
          _.get(user, "region.data.id", "")
      )
      setUserName(_.get(user, "name", "")!)
    }
  }, [user])
  //here we place data about user in context

  return <Context.Provider value={{ app }}>{children}</Context.Provider>
}

const Router = () => {
  const { data, loading } = useHomeQuery()

  const { user } = useAuth()
  const userRole = user?.role?.data?.attributes?.name

  const isComingSoon = data?.home?.data?.attributes?.comingSoon

  let routes = comingSoonRoutes

  switch (userRole) {
    case "Salon":
      {
        if (user?.salon?.data?.attributes?.isActive && user?.isAppActivated) {
          routes = activatedSalonRoutes
        } else {
          routes = guestRoutes
        }
      }
      break
    case "Region":
    case "Distributor":
    case "Sales Manager":
    case "DSC":
      routes = otherLevelRoutes
      break
    default:
      routes = guestRoutes
  }

  if (isComingSoon && !loading) {
    routes = comingSoonRoutes
  }

  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} />
}

const App: FC = memo(() => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ContextProvider>
          <ConfigProvider theme={theme} wave={{ disabled: true }}>
            <AntApp>
              <Router />
            </AntApp>
          </ConfigProvider>
        </ContextProvider>
      </AuthProvider>
    </ApolloProvider>
  )
})

export { useApp }

export default App
