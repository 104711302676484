import { initMarker } from "@bn-digital/sdk"
import { createRoot } from "react-dom/client"
import App from "./components/app"

Promise.resolve(!!import.meta.env.WEBSITE_MARKER_ID).then(enabled =>
  initMarker({ enabled, destination: import.meta.env.WEBSITE_MARKER_ID })
)

const rootElement = document.querySelector("#root") as HTMLElement

createRoot(rootElement).render(<App />)
